import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toastAlert } from '../../lib/toastAlert'
// import logo from "assets/img/favicon-16x16.png";

// import action
import { setCurrentUser, login } from './../../actions/users';

// import lib
import validation from './Validation';
import isEmpty from '../../lib/isEmpty';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textDanger: {
    color: "#f32828",
  }
}));


const initialFormValue = {
  email: '',
  password: '',
}


export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [tfastatus,settfastatus] = useState(false);
  const [flag,setFlag] = useState()

  const {
    email,
    password,
    tfa
  } = formValue

  // function
  const handleFormSubmit = async (e) => {
    if((tfastatus && tfa!=null && tfa!=undefined && tfa!="" )||(!tfastatus)){
    e.preventDefault();
    let reqData = {
      email,
      password,
      tfa,
      "tfastatus":tfastatus ? "yes":"no",
    }
    let { tfas , error, result } = await login(reqData, dispatch);
    if(tfas){
      console.log(tfas,'tfassssss');
      settfastatus(true)
    }else if (isEmpty(error)) {
      setFormValue(initialFormValue)
      history.push('/Dashboard')
      dispatch(setCurrentUser(result))
    } else {
      setValidateError(error);
    }
   }else{
    toastAlert('error', "Enter 2FA Digit", 'address')
   }
  }
  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } })
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError(validation(formData))
  }

  useEffect(() => {
    setValidateError(validation(formValue))
  }, [])


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {/* <Box
        component="img"
        sx={{
          height: 233,
          width: 20,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="nilwire"
        src="/logo.png"
      /> */}
    {/* <img src="../assets/images/logo.png" alt="profile" className="img-fluid" /> */}

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
            autoFocus
          />
          {
            toched.email && validateError.email && <p className="error-message">{validateError.email}</p>
          }
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {
            toched.password && validateError.password && <p className="error-message">{validateError.password}</p>
          }
          {tfastatus &&
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="tfa"
              onChange={handleChange}
              onBlur={handleBlur}
              label="6 Digit 2fa"
              id="tfa"
              // name="tfa"
            />
          }

          {
            tfastatus && toched.tfa && validateError.tfa && <p className="error-message">{validateError.tfa}</p>
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isEmpty(validateError)}
          >
            Sign In
          </Button>
          {/*<Grid container>
            <Grid item xs>
              <Link href="/Forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>*/}
        </form>
      </div>
      <Box mt={8}>
      </Box>
    </Container>
  );
}
