/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import lodash from "lodash";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Icon
} from "@material-ui/core";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const [collapse, setCollapse] = useState("");

  // redux-state
  const authData = useSelector((state) => state.auth);

  const handleClick = (collapseId) => {
    if (collapseId == collapse) {
      setCollapse("");
      return;
    }
    setCollapse(collapseId);
  };

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list + " main_menu"}>
      {routes.map((prop, key) => {
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });

        if (prop.isSideMenu) {
          if (prop.type == "multi") {
            let checkValue = true;
            if (authData && authData.isAuth == true) {
              if (
                authData.role != "superadmin" &&
                authData.restriction &&
                authData.restriction.length > 0
              ) {
                let data = lodash.intersectionBy(
                  authData.restriction,
                  prop.child,
                  "path"
                );
                if (data && data.length > 0) {
                  checkValue = true;
                }
              } else if (authData.role == "superadmin") {
                checkValue = true;
              }
            }

            if (checkValue) {
              return (
                <>
                  <NavLink
                    to={prop.path}
                    className={classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem button onClick={() => handleClick(prop.id)}>
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses
                          )}
                        />
                      )}

                      <ListItemText
                        primary={prop.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses
                        )}
                        disableTypography={true}
                      />
                      {collapse == prop.id ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  </NavLink>
                  <Collapse
                    in={collapse == prop.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {prop.child.map((el, id) => {
                        const whiteFontClasses = classNames({
                          [" " + classes.whiteFont]: activeRoute(el.path)
                        });

                        let listSubItemClasses;
                        listSubItemClasses = classNames({
                          [" " + classes[color]]: activeRoute(el.path)
                        });
                        let check = true;
                        if (authData && authData.isAuth == true) {
                          if (
                            authData.role != "superadmin" &&
                            authData.restriction &&
                            authData.restriction.length > 0
                          ) {
                            let data = authData.restriction.find(
                              (value) => value.path == el.path
                            );
                            if (data) {
                              check = true;
                            }
                          } else if (authData.role == "superadmin") {
                            check = true;
                          }
                        }

                        if (check) {
                          return (
                            <NavLink
                              to={el.path}
                              className={classes.item}
                              key={id}
                            >
                              <ListItem
                                button
                                className={
                                  classes.itemLink + listSubItemClasses
                                }
                                // className={classes.itemLink + classes.nested + listItemClasses + " sub_menu_items"}
                              >
                                {typeof el.icon === "string" ? (
                                  <Icon
                                    className={classNames(
                                      classes.itemIcon,
                                      whiteFontClasses
                                    )}
                                  >
                                    {el.icon}
                                  </Icon>
                                ) : (
                                  <el.icon
                                    className={classNames(
                                      classes.itemIcon,
                                      whiteFontClasses
                                    )}
                                  />
                                )}

                                <ListItemText
                                  primary={el.name}
                                  className={classNames(
                                    classes.itemText,
                                    whiteFontClasses
                                  )}
                                  disableTypography={true}
                                />
                              </ListItem>
                            </NavLink>
                          );
                        }
                      })}
                    </List>
                  </Collapse>
                </>
              );
            }
          } else {
            let check = false;
            if (authData && authData.isAuth == true) {
              if (
                authData.role != "superadmin" &&
                authData.restriction &&
                authData.restriction.length > 0
              ) {
                let data = authData.restriction.find(
                  (value) => value.path == prop.path
                );
                if (data) {
                  check = true;
                }
              } else if (authData.role == "superadmin") {
                check = true;
              }
            }

            if (check) {
              return (
                <NavLink
                  to={prop.path}
                  className={classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      />
                    )}
                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            }
          }
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="nilwirenftniarus/dashboard"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        // target="_blank"
      >
        <div className={classes.logoImage}>
          {/* <img src={logo} alt="logo" className={classes.img} /> */}
          Admin
        </div>
        {/* {logoText} */}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
